import React from 'react'
import { TableHeaderContainer, TableHeaderRightSection } from './styled'

export const TableHeader = () => {
  return (
    <TableHeaderContainer>
      <div>
        <span>Producto</span>
      </div>
      <TableHeaderRightSection className="left-section-header">
        <span className="section-title">Precio unitario</span>
        <span className="section-title">Cantidad</span>
        <span className="section-title">Precio total</span>
      </TableHeaderRightSection>
    </TableHeaderContainer>
  )
}
