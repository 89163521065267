import React from 'react'
import { sendRemoveProductToGTM, useCart, useLocation, useShoppingCart } from '@ecommerce/shared'
import { IconRemove, ProductsQuantity, Title, TitleRemove, Wrapper, WrapperRemoveButton, WrapperTitle } from './styled'
import { Icon } from '../../../components/Icon/Icon'
import EmptyCartAlert from '../Alerts/EmptyCartAlert'

export const ProductsCartTitle = () => {
  const {
    state: { globalQuantity, byHash },
    empty: onDeleteAll,
  } = useShoppingCart()

  const { isBolivia } = useLocation()
  const { setOpenEmptyCartAlert, openEmptyCartAlert } = useCart()

  const onRemoveAllProducts = () => {
    sendRemoveProductToGTM(Object.values(byHash), isBolivia())
    setOpenEmptyCartAlert(false)
    return onDeleteAll()
  }

  return (
    <Wrapper>
      {openEmptyCartAlert && (
        <EmptyCartAlert
          onConfirm={() => onRemoveAllProducts()}
          onClose={() => setOpenEmptyCartAlert(false)}
          title="Vaciar carro"
          message="¿Estás seguro que quieres vaciar tu carro de compras?"
          icon={<Icon iconId="trash" size={25} fillColor="red" />}
        />
      )}
      <WrapperTitle className="wrapper-title">
        <Title className="title">Mi Carro</Title>
        <ProductsQuantity className="products-quantity">({globalQuantity} productos)</ProductsQuantity>
      </WrapperTitle>
      <WrapperRemoveButton
        onClick={() => {
          setOpenEmptyCartAlert(true)
        }}
        type="button"
        className="wrapper-remove-cart"
      >
        <TitleRemove className="title-remove">Vaciar carro</TitleRemove>
        <IconRemove iconId="trash" size={20} className="icon-remove" />
      </WrapperRemoveButton>
    </Wrapper>
  )
}
