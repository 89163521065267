import React from 'react'
import { navigate } from 'gatsby'
import {
  EmptyButton,
  EmptyCartContainer,
  EmptyCartWrapper,
  EmptyIcon,
  EmptySubtitle,
  EmptyTitle,
  EmptyTitleContainer,
} from './EmptyCart.styled'

const EmptyCart = () => {
  return (
    <EmptyCartContainer>
      <EmptyCartWrapper>
        <EmptyIcon />
        <EmptyTitleContainer>
          <EmptyTitle>Tu carro está vacío</EmptyTitle>
          <EmptySubtitle>¡Descubre todos los productos que te podrían interesar!</EmptySubtitle>
        </EmptyTitleContainer>
        <EmptyButton onClick={() => navigate('/promotion')}>Ver Catálogo</EmptyButton>
      </EmptyCartWrapper>
    </EmptyCartContainer>
  )
}

export default EmptyCart
