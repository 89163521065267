import React from 'react'
import styled from 'styled-components'
import { breakpoints, useCart, useLocation, useResolution, useShoppingCart } from '@ecommerce/shared'
import { ProductsCartTitle } from './Title/ProductsCartTitle'
import { ProductsCartTable } from './Table/ProductsCartTable'
import { SummaryCart } from './Summary/SummaryCart'
import EmptyCart from './EmptyCart/EmptyCart'
import ShoppingContent from '../../components/ShoppingCart/ShoppingContent'
import Summary from '../../components/ShoppingCart/Summary'
import EmptyCartAlert from './Alerts/EmptyCartAlert'
import { Icon } from '../../components/Icon/Icon'
import { sendRemoveProductEvent } from '../../utils/events'
import { ProductsCartMobileWrapper, ReturnableAlertWrapper, Suggested, Wrapper } from './CartView.styled'
import SuggestedMobile from './Suggested/SuggestedMobile'
import { SuggestedProducts, SuggestedTitle } from './Suggested/styled'
import SuggestedSlider from './Suggested/SuggestedSlider'
import ReturnableAlert from '../../components/Checkout/components/ReturnableAlert'

const ProductsCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  border-radius: 4px;
  padding-inline: 24px;
  padding-block-start: 24px;
  min-width: 721px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    padding-inline: 16px;
    margin-block-end: 32px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    height: 516px;
    padding-block-end: 48px;
    padding-inline: 24px;
    margin-block-end: 0;
    min-width: 603px;
  }
  @media screen and (${breakpoints.desktopWide.min}) {
    min-width: 897px;
  }
`

const ProductsCart = () => {
  return (
    <ProductsCartContainer>
      <ProductsCartTitle />
      <ReturnableAlert />
      <ProductsCartTable />
    </ProductsCartContainer>
  )
}

const WrapperMobile = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    min-width: 721px;
    width: 721px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    gap: 24px;
    margin-block-end: 56px;
    min-width: 928px;
    max-width: 928px;
  }
  @media screen and (${breakpoints.desktop.medium}) {
    max-width: 1080px;
    min-width: 1080px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    gap: 24px;
    max-width: 1280px;
    min-width: 1280px;
  }
`

const CartView = () => {
  const { isBolivia } = useLocation()

  const { openEmptyCartAlert, setOpenEmptyCartAlert } = useCart()
  const { state, getProducts, empty: onDeleteAll } = useShoppingCart()
  const { isDesktop, isMobile, isTablet } = useResolution()

  function onRemoveAllProducts() {
    sendRemoveProductEvent(Object.values(state.byHash), isBolivia())
    setOpenEmptyCartAlert(false)
    return onDeleteAll()
  }

  return (
    <Wrapper hasProducts={!getProducts().length}>
      {openEmptyCartAlert && (
        <EmptyCartAlert
          onConfirm={() => onRemoveAllProducts()}
          onClose={() => setOpenEmptyCartAlert(false)}
          title="Vaciar carro"
          message="¿Estás seguro que quieres vaciar tu carro de compras?"
          icon={<Icon iconId="trash" size={25} fillColor="green" />}
        />
      )}
      {getProducts().length === 0 && <EmptyCart />}
      <WrapperMobile>
        {isMobile && getProducts().length > 0 && (
          <>
            <ProductsCartMobileWrapper>
              <ReturnableAlertWrapper>
                <ReturnableAlert />
              </ReturnableAlertWrapper>
              <ShoppingContent className="items" />
              {isMobile && getProducts().length > 0 && <SuggestedMobile />}
            </ProductsCartMobileWrapper>
          </>
        )}
        {isTablet && getProducts().length > 0 && <Summary isLoading={false} isDisabled={false} />}
        {!isMobile && getProducts().length > 0 && (
          <>
            <ProductsCart />
          </>
        )}
        {isDesktop && getProducts().length > 0 && <SummaryCart />}
      </WrapperMobile>
      {!isMobile && getProducts().length > 0 && (
        <Suggested>
          <>
            <SuggestedTitle>Te podría interesar...</SuggestedTitle>
            <SuggestedProducts>
              <SuggestedSlider />
            </SuggestedProducts>
          </>
        </Suggested>
      )}
    </Wrapper>
  )
}

export default CartView
