import React, { useEffect } from 'react'
import { useShoppingCart, useAuth, useResolution, ZIndex } from '@ecommerce/shared'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { PgPageProps } from '../types/PgPages'
import CartView from '../templates/cart/CartView'
import Layout from '../components/Layout'
import ArrowLeftIcon from '../templates/cart/Icons/ArrowLeftIcon'
import MobileLocationSelector from '../components/MobileLocationSelector/MobileLocationSelector'
import { sendPageViewEvent } from '../utils/events'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 212px);
  background: ${({ theme }) => theme.colors.grey};
`

const NavbarWrapper = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZIndex.high};
  background: beige;
  height: 72px;
`

const Navbar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-block: 16px;
  padding-inline: 16px;
  background-color: ${(props) => props.theme.colors.red};
  box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
  span {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 17px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    svg {
      margin-inline-end: 12px;
      :hover {
        cursor: pointer;
      }
    }
  }

  span > span {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: -0.06px;
    margin-inline-start: 8px;
  }
`

const IconWrapper = styled.div`
  display: flex;
`

const CartPage = ({ pageContext: { productCategories } }: PgPageProps) => {
  const { isMobile } = useResolution()

  const {
    getConnectifCart,
    state: { globalQuantity },
  } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const cart = getConnectifCart()
    const entityInfo = getEntityInfo()
    const pageInfo = {
      title: 'Cart Page',
      event: 'cart-page',
    }
    sendPageViewEvent({ cart, entityInfo, ...pageInfo })
  }, [])

  return (
    <Layout
      customNavbar={
        isMobile ? (
          <>
            <NavbarWrapper>
              <Navbar>
                <IconWrapper onClick={() => navigate('/')}>
                  <ArrowLeftIcon />
                </IconWrapper>
                <span>
                  Mi Carro <span>({globalQuantity} productos)</span>
                </span>
              </Navbar>
            </NavbarWrapper>
            <MobileLocationSelector />
          </>
        ) : null
      }
      pathName="/cart"
      categories={productCategories}
      title="Carrito de compra"
    >
      <Wrapper>
        <CartView />
      </Wrapper>
    </Layout>
  )
}

export default CartPage
