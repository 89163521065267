import React from 'react'
import { TableHeader } from './ProductsCardHeader'
import { TableBody } from './ProductsCardBody'
import { TableContainer } from './styled'

export const ProductsCartTable = () => {
  return (
    <TableContainer>
      <TableHeader />
      <TableBody />
    </TableContainer>
  )
}
