import React, { useState, useEffect, useMemo } from 'react'
import { useShoppingCart, getStoredDistributionCenter, FormattedProduct, slugify } from '@ecommerce/shared'
import { TableBodyContainer } from './styled'
import { ProductCard } from './ProductCard'
import GiftProducts from './GiftProducts'

type GiftLineItem = {
  quantity: number
  sku: number
}

export const TableBody = () => {
  const [algoliaProducts, setAlgoliaProducts] = useState<FormattedProduct[]>([])
  const [giftLineItems, setGiftLineItems] = useState<GiftLineItem[]>()

  const {
    getProducts,
    getAlgoliaProducts,
    state,
    state: { promotions },
  } = useShoppingCart()
  const currentDistributionCenter = useMemo(() => getStoredDistributionCenter(), [getStoredDistributionCenter])
  const products = useMemo(() => getProducts(), [getProducts])

  const getAlgoliaProductsInShoppingCart = async (slugLocation: string) => {
    setAlgoliaProducts(await getAlgoliaProducts({ slugLocation }))
  }

  useEffect(() => {
    setGiftLineItems(
      state?.giftLineItems?.map(({ sku, discountQuantity = 0 }) => ({
        sku: Number(sku),
        quantity: discountQuantity,
      })) || [],
    )
  }, [state.giftLineItems])

  useEffect(() => {
    if (!currentDistributionCenter) return
    const slugLocation = slugify(currentDistributionCenter.commerceLayer.stockLocation.name)

    getAlgoliaProductsInShoppingCart(slugLocation)
  }, [currentDistributionCenter, products.length])

  return (
    <TableBodyContainer>
      {products.map((product) => (
        <ProductCard
          key={product.skuCode}
          promotion={promotions && promotions[product.skuCode]}
          product={product}
          returnabilityLabel={
            algoliaProducts?.find((algoliaProduct) => algoliaProduct.skuCode === product.skuCode)?.returnabilityLabel
          }
        />
      ))}
      {currentDistributionCenter ? (
        <GiftProducts
          key={giftLineItems?.length}
          slugLocation={currentDistributionCenter?.slug}
          items={giftLineItems}
        />
      ) : null}
    </TableBodyContainer>
  )
}
