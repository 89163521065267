import { FormattedProduct, ProductCard, useResolution } from '@ecommerce/shared'
import React, { useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import { useSuggestedProducts } from '@ecommerce/shared/src/context/Cart/suggested.context'
import { SuggestedSliderWrapper, SwiperStyled } from './styled'

const SuggestedSlider = () => {
  const { isMobile, isTablet, isDesktop } = useResolution()
  const { suggestedProducts, getSuggestedProducts } = useSuggestedProducts()

  const getGap = () => {
    if (isMobile) return 14
    if (isTablet) return 8
    if (isDesktop) return 24
  }

  useEffect(() => {
    getSuggestedProducts()
  }, [])

  return (
    <SuggestedSliderWrapper>
      <SwiperStyled
        pagination={{ type: 'bullets', clickable: true }}
        navigation
        slidesPerView="auto"
        spaceBetween={getGap()}
        className="swiper"
      >
        {suggestedProducts.map((product: FormattedProduct) => (
          <SwiperSlide className="slider-slide">
            <ProductCard
              isSuggested
              className="product-card"
              useDragHandler={false}
              type="large"
              product={{ ...product, isSuggested: true }}
            />
          </SwiperSlide>
        ))}
      </SwiperStyled>
    </SuggestedSliderWrapper>
  )
}

export default SuggestedSlider
