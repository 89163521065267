import React, { useEffect, useMemo, useState } from 'react'
import {
  useShoppingCart,
  getReturnabilityType,
  ReturnabilityType,
  getStoredDistributionCenter,
  slugify,
  useLocation,
} from '@ecommerce/shared'
import AlertMessage from '../../../AlertMessage'

const ReturnableAlert = () => {
  const [returnableProductsCount, setReturnableProductsCount] = useState(0)

  const { getProducts, getAlgoliaProducts } = useShoppingCart()
  const { textByCountry } = useLocation()

  const currentDistributionCenter = useMemo(() => getStoredDistributionCenter(), [getStoredDistributionCenter])
  const products = useMemo(() => getProducts(), [getProducts])

  const getReturnableProductsInShoppingCart = async (slugLocation: string) => {
    const algoliaProducts = await getAlgoliaProducts({ slugLocation })

    if (algoliaProducts.length > 0) {
      const returnableCount = algoliaProducts.reduce((accumulator, algoliaProduct) => {
        if (
          getReturnabilityType({ returnabilityLabel: algoliaProduct.returnabilityLabel }) ===
          ReturnabilityType.RETURNABLE
        ) {
          return accumulator + (products.find((product) => product.skuCode === algoliaProduct.skuCode)?.quantity ?? 0)
        }
        return accumulator + 0
      }, 0)
      setReturnableProductsCount(returnableCount)
    }
  }

  useEffect(() => {
    if (!currentDistributionCenter) return

    const slugLocation = slugify(currentDistributionCenter.commerceLayer.stockLocation.name)

    getReturnableProductsInShoppingCart(slugLocation)
  }, [currentDistributionCenter, products])

  if (returnableProductsCount === 0) return null

  const productText = returnableProductsCount > 1 ? 'productos' : 'producto'

  return (
    <AlertMessage type="advice">
      <span>
        {textByCountry(
          `Llevas ${returnableProductsCount} ${productText} sin envase, `,
          `Llevas ${returnableProductsCount} ${productText} sin botella, `,
        )}
      </span>
      {textByCountry(
        `recuerda entregar tus envases retornables vacías al repartidor.`,
        `recuerda entregar tus botellas retornables vacías al repartidor.`,
      )}
    </AlertMessage>
  )
}

export default ReturnableAlert
