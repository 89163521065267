import {
  ModalBackground,
  ProductCart,
  PromotionDetail,
  PromotionType,
  showToast,
  useLocation,
  useShoppingCart,
  toNumber,
  useDisableAddProductButton,
  getReturnabilityType,
  ReturnabilityType,
} from '@ecommerce/shared'
import { IconButton } from '@ecommerce/shared/src/components/atoms/IconButton'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Icon } from '../../../components/Icon/Icon'
import DotsLoader from '../../../components/DotsLoader'
import ConfirmModal from '../../../components/ShoppingCart/ConfirmModal'
import {
  BottomSection,
  ContentSection,
  ContentTop,
  DiscountContainer,
  DiscountPrice,
  DiscountReturnabilityContainer,
  LeftSection,
  PricesWrapper,
  ProductCardWrapper,
  ProductImage,
  ProductPrice,
  QuantityWrapper,
  RemoveIcon,
  RetornabilityContent,
  ReturnabilityContainer,
  ReturnabilityTag,
  ReturnabilityText,
  ReturnabilityWrapper,
  RightSection,
  Title,
  TotalPrice,
} from './styled'
import { RetornabilityIcon } from '../../../components/Icons'

interface Props {
  product: ProductCart
  promotion?: PromotionDetail
  isGift?: boolean
  giftQuantity?: number
  returnabilityLabel?: string
}

export const ProductCard = ({ product, promotion, isGift = false, giftQuantity, returnabilityLabel }: Props) => {
  const { textByCountry, toCurrency } = useLocation()
  const {
    state: { promotionsUpdatedAt },
    disabledAddButtonLimitedProducts,
    getOrderId,
    removeProduct,
    updateProduct,
  } = useShoppingCart()

  const [loadingPrice, setLoadingPrice] = useState(false)
  const [productQuantity, setProductQuantity] = useState(product.quantity)
  const [openDeleteProductAlert, setOpenDeleteProductAlert] = useState(false)
  const [firestoreUpdateTimeout, setFirestoreUpdateTimeout] = useState(0)
  const [isModifiedSuggestedProduct, setIsModifiedSuggestedProduct] = useState(false)

  const promotionData = promotion?.promotion?.type !== PromotionType.A_DISCOUNTS_B ? promotion : undefined
  const discountAmount = promotionData?.discountAmount ?? 0

  const orderId = getOrderId()

  const disabledAddButton = useDisableAddProductButton({ skuCode: product.skuCode, quantity: productQuantity })

  const resetFirebaseUpdateTimeout = () => {
    clearTimeout(firestoreUpdateTimeout)
    setFirestoreUpdateTimeout(
      setTimeout(async () => {
        await updateProduct(product, productQuantity, orderId)
      }, 500),
    )
  }

  useEffect(() => {
    if (loadingPrice) {
      resetFirebaseUpdateTimeout()
    }
  }, [productQuantity, loadingPrice])

  useEffect(() => {
    setLoadingPrice(false)
  }, [promotionsUpdatedAt])

  useEffect(() => {
    if (product.isSuggested) setProductQuantity(product.quantity)
    if (isModifiedSuggestedProduct) setLoadingPrice(true)
  }, [product.quantity])

  const onIncreaseProduct = (productCart: ProductCart) => {
    setLoadingPrice(true)
    if (product.isSuggested) setIsModifiedSuggestedProduct(true)
    if (disabledAddButtonLimitedProducts(product, productQuantity)) {
      showToast({
        content: `Puedes llevar un máximo de 3 unidades de ${productCart.title} por compra.`,
        type: 'info',
      })
      return
    }
    setProductQuantity(productQuantity + 1)
  }

  const onDecreaseProduct = () => {
    if (product.isSuggested) setIsModifiedSuggestedProduct(true)
    if (productQuantity === 1) {
      setOpenDeleteProductAlert(true)
      return
    }
    setLoadingPrice(true)
    setProductQuantity(productQuantity - 1)
  }

  const returnabilityType: ReturnabilityType = useMemo(() => {
    return getReturnabilityType({ returnabilityLabel })
  }, [returnabilityLabel])

  const returnabilityLabelText = useMemo(() => {
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE) {
      return `Incluye ${textByCountry('envase', 'botella')}`
    }
    if (returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) {
      return `Incluye ${textByCountry('envase', 'botella')} y jaba`
    }

    return `Retorna tu ${textByCountry('envase', 'botella')}`
  }, [returnabilityType])

  return (
    <Fragment>
      {openDeleteProductAlert && (
        <ModalBackground>
          <ConfirmModal
            closeModal={() => setOpenDeleteProductAlert(false)}
            onDeleteAll={() => {
              setOpenDeleteProductAlert(false)
              return removeProduct(product, orderId)
            }}
            image={product.image}
            message={product.title}
            centerModal
          />
        </ModalBackground>
      )}

      <ProductCardWrapper showReturnableTag={returnabilityType === ReturnabilityType.RETURNABLE}>
        <ReturnabilityWrapper>
          {returnabilityType === ReturnabilityType.RETURNABLE && (
            <ReturnabilityTag returnability={returnabilityType}>
              <RetornabilityContent>
                <RetornabilityIcon />
                {returnabilityLabelText}
              </RetornabilityContent>

              <a
                href={textByCountry(
                  'https://embonor.micoca-cola.cl/momento/sustentable',
                  'https://www.micoca-cola.bo/momento/sustentable ',
                )}
                target="_blank"
                rel="noreferrer"
              >
                Más info
              </a>
            </ReturnabilityTag>
          )}
        </ReturnabilityWrapper>
        {!isGift && (
          <RemoveIcon
            showReturnableTag={returnabilityType === ReturnabilityType.RETURNABLE}
            iconId="trash"
            size={16}
            fillColor="red"
            onClick={() => {
              setOpenDeleteProductAlert(true)
            }}
          />
        )}
        <LeftSection>
          <ProductImage className="image" alt={product.title} src={product.image} />
          <ContentSection>
            <ContentTop>
              <Title>{product.title.charAt(0) + product.title.slice(1).toLowerCase()}</Title>
              <DiscountReturnabilityContainer>
                {product.discount > 0 && !isGift && (
                  <DiscountContainer>
                    <span className="discount-badge">{product.discount}% Dcto.</span>
                  </DiscountContainer>
                )}
                {(returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE ||
                  returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX) && (
                  <ReturnabilityContainer returnabilityType={returnabilityType}>
                    <Icon iconId="info_outline" size={12} className="returnability-icon" />
                    <ReturnabilityText>{returnabilityLabelText}</ReturnabilityText>
                  </ReturnabilityContainer>
                )}
              </DiscountReturnabilityContainer>
            </ContentTop>
            {!isGift && (
              <BottomSection
                className="content-bottom"
                onClick={() => {
                  setOpenDeleteProductAlert(true)
                }}
                type="button"
              >
                <Icon iconId="trash" size={16} className="remove-icon" />
                <span className="remove">Eliminar</span>
              </BottomSection>
            )}
          </ContentSection>
        </LeftSection>
        <RightSection>
          <PricesWrapper>
            {product.discount > 0 && (
              <DiscountPrice className="discount-price">{!isGift ? toCurrency(product.rawPrice) : '-'}</DiscountPrice>
            )}
            {!isGift && (
              <ProductPrice hasDiscount={product.hasDiscount} className="product-price">
                {toCurrency(product.price)}
              </ProductPrice>
            )}
          </PricesWrapper>
          <QuantityWrapper isGift={isGift}>
            <IconButton
              onClick={() => onDecreaseProduct()}
              className="decrease-quantity"
              iconId="decrease-cart"
              size={13}
              disabled={isGift}
            />
            <span className="quantity">{toNumber(giftQuantity || productQuantity)}</span>
            <IconButton
              disabled={disabledAddButton || disabledAddButtonLimitedProducts(product, productQuantity) || isGift}
              onClick={() => onIncreaseProduct(product)}
              className="add-quantity"
              iconId="add-cart"
              size={13}
            />
          </QuantityWrapper>
          {!loadingPrice ? (
            <TotalPrice className="total-price" hasDiscount={product.hasDiscount}>
              {!isGift
                ? toCurrency(
                    Math.ceil(
                      discountAmount
                        ? product.rawPrice * product.quantity - discountAmount
                        : product.price * product.quantity,
                    ),
                  )
                : 'Regalo'}
            </TotalPrice>
          ) : (
            <TotalPrice hasDiscount={product.hasDiscount} className="total-price">
              <DotsLoader />
            </TotalPrice>
          )}
        </RightSection>
      </ProductCardWrapper>
    </Fragment>
  )
}
