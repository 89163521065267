import styled, { keyframes } from 'styled-components'
import { breakpoints, hexToRGBA, ZIndex } from '@ecommerce/shared'
import { Swiper } from 'swiper/react'

const slideInAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const slideOutAnimation = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`

export const SuggestedTitle = styled.p`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.254px;
  font-weight: bold;
  margin: 0;
  color: ${({ theme }) => hexToRGBA(theme.colors.black80, 0.75)};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.111px;
  }
`

export const SuggestedProducts = styled.div`
  width: 100%;
`

export const SuggestedMobileWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-block: 24px;
  padding-inline: 16px;
  background: ${({ theme }) => theme.colors.grey20};
  border-radius: 24px 24px 0 0;
  z-index: ${ZIndex.veryHigh};
  opacity: 0;
  visibility: hidden;

  &.visible {
    animation: ${slideInAnimation} 0.5s ease-in-out forwards;
    opacity: 1;
    visibility: visible;
  }

  &.hidden {
    animation: ${slideOutAnimation} 0.5s ease-in-out forwards;
    opacity: 0;
    visibility: hidden;
  }
`

export const SuggestedSliderWrapper = styled.div`
  width: 100%;
`

export const SwiperStyled = styled(Swiper)`
  padding-block-end: 24px;
  .swiper {
    &-pagination {
      display: flex;
      justify-content: center;
      bottom: 0;
      &-bullet {
        height: 8px;
        width: 8px;
        &-active {
          background: ${({ theme }) => theme.colors.red};
          opacity: 0.75;
        }
      }
    }
    &-wrapper {
      margin-block-end: 16px;
      background: ${({ theme }) => theme.colors.grey20};
    }

    &-button {
      &-next,
      &-prev {
        display: none;
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;

        &::after {
          font-weight: bolder;
          font-size: 18px;
        }

        &:hover {
          opacity: 0.5;
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    }
  }

  .slider {
    padding: 8px 20px;

    &-slide {
      width: 224px;
    }
  }

  .product-card {
    box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .swiper {
      &-pagination {
        gap: 15px;
        &-bullet {
          height: 15px;
          width: 15px;
        }
      }
      &-wrapper {
        margin-block-end: 16px;
        background: ${({ theme }) => theme.colors.grey};
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .swiper {
      &-wrapper {
        margin-block-end: 16px;
        background: ${({ theme }) => theme.colors.grey};
      }
      &-button {
        &-next,
        &-prev {
          display: flex;
        }
      }
    }

    .slider {
      &-slide {
        width: 277px;
      }
    }
  }
`
