import React from 'react'
import styled from 'styled-components'
import { breakpoints, Button } from '@ecommerce/shared'
import EmptyCartIcon from './EmptyCartIcon'

export const EmptyCartContainer = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin: 0 auto;
  width: 100%;
  @media screen and (${breakpoints.desktop.min}) {
    margin-inline: 80px;
  }
`

export const EmptyCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 256px;
  gap: 32px;
  padding-block: 80px;
  @media screen and (${breakpoints.desktop.min}) {
    width: 328px;
    padding-block-start: 110px;
    padding-block-end: 86px;
  }
`

export const EmptyIcon = styled(EmptyCartIcon)`
  width: 124px;
  height: 124px;
`

export const EmptyTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const EmptyTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.111429px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black80};
`

export const EmptySubtitle = styled.p`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.15px;
  margin: 0;
`

export const EmptyButton = styled(Button)`
  width: 100%;

  @media screen and (${breakpoints.desktop.min}) {
    width: 256px;
  }
`
