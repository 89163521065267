import React from 'react'

const ArrowLeftIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1166 20.9933C27.614 20.9355 28 20.5128 28 20C28 19.4477 27.5523 19 27 19H15.4149L20.7071 13.7071L20.7903 13.6129C21.0953 13.2206 21.0676 12.6534 20.7071 12.2929C20.3166 11.9024 19.6834 11.9024 19.2929 12.2929L12.2933 19.2924L12.2929 19.2929L12.2103 19.3864C12.236 19.3534 12.2638 19.322 12.2933 19.2924M12.2936 20.7078L19.2929 27.7071L19.3871 27.7903C19.7794 28.0953 20.3466 28.0676 20.7071 27.7071L20.7903 27.6129C21.0953 27.2206 21.0676 26.6534 20.7071 26.2929L15.4149 21H13C12.7242 21 12.4745 20.8884 12.2936 20.7078Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 20C28 20.5128 27.614 20.9355 27.1166 20.9933L27 21H13C12.4477 21 12 20.5523 12 20C12 19.4872 12.386 19.0645 12.8834 19.0067L13 19H27C27.5523 19 28 19.4477 28 20Z"
        fill="white"
      />
      <mask id="mask0_15627_19038" maskUnits="userSpaceOnUse" x="12" y="12" width="16" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1166 20.9933C27.614 20.9355 28 20.5128 28 20C28 19.4477 27.5523 19 27 19H15.4149L20.7071 13.7071L20.7903 13.6129C21.0953 13.2206 21.0676 12.6534 20.7071 12.2929C20.3166 11.9024 19.6834 11.9024 19.2929 12.2929L12.2933 19.2924L12.2929 19.2929L12.2103 19.3864C12.236 19.3534 12.2638 19.322 12.2933 19.2924M12.2936 20.7078L19.2929 27.7071L19.3871 27.7903C19.7794 28.0953 20.3466 28.0676 20.7071 27.7071L20.7903 27.6129C21.0953 27.2206 21.0676 26.6534 20.7071 26.2929L15.4149 21H13C12.7242 21 12.4745 20.8884 12.2936 20.7078Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_15627_19038)" />
    </svg>
  )
}

export default ArrowLeftIcon
