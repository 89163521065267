import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

interface StyledProps {
  hasProducts?: boolean
}

export const Wrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 16px;
  padding-block-start: ${({ hasProducts }) => (hasProducts ? '40px' : '0')};
  padding-block-end: 82px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    padding-block-start: 24px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding-block-start: 48px;
    padding-block-end: 56px;
  }
`
export const ProductsCartMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  .items {
    padding-block-start: 0;
  }
`

export const Suggested = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-block-end: 160px;
  min-width: 721px;
  max-width: 721px;

  @media screen and (${breakpoints.desktop.min}) {
    min-width: 928px;
    max-width: 928px;
    margin-block-end: 0;
    gap: 24px;
  }
  @media screen and (${breakpoints.desktop.medium}) {
    min-width: 1080px;
    max-width: 1080px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    min-width: 1280px;
    max-width: 1280px;
  }
`

export const ReturnableAlertWrapper = styled.div`
  padding-inline: 21.5px;
  margin-top: 16px;
`
