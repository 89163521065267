import styled, { css } from 'styled-components'
import { breakpoints, Image, ReturnabilityType } from '@ecommerce/shared'
import { Icon } from '../../../components/Icon/Icon'

interface ReturnabilityContainerProps {
  returnabilityType?: ReturnabilityType
}

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
`

export const TableHeaderContainer = styled.div`
  display: none;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    display: flex;
    justify-content: space-between;
    padding-inline: 8px;
    padding-block-end: 8px;
    border-block-end: 1px solid ${({ theme }) => theme.colors.platinum60};
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.06px;
    color: ${({ theme }) => theme.colors.platinum80};
  }
`

export const TableHeaderRightSection = styled.div`
  display: flex;
  gap: 32px;

  .section-title {
    padding-inline: 8px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    span:first-child {
      display: none;
      gap: 40px;
    }
  }

  @media screen and (${breakpoints.desktop.medium}) {
    span:first-child {
      display: flex;
    }
  }
`

export const TableBodyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: auto;
  padding-block-end: 32px;
  overflow-y: auto;
  scrollbar-width: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 100%;
    display: initial;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  @media screen and (${breakpoints.desktop.min}) {
    height: 385px;
  }
`

export const ProductCardWrapper = styled.div<{ showReturnableTag: boolean }>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ showReturnableTag }) => {
    if (showReturnableTag) {
      return css`
        paddind-top: 0px;
        padding-bottom: 16px;
      `
    }
    return css`
      padding-block: 16px;
    `
  }}

  padding-inline: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey20};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  background: ${({ theme }) => theme.colors.white};
`

export const RemoveIcon = styled(Icon)<{ showReturnableTag: boolean }>`
  position: absolute;
  margin-inline-end: 16px;
  margin-block-start: 16px;
  right: 0;
  top: ${({ showReturnableTag }) => (showReturnableTag ? '20px' : '0')};
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }

  @media screen and (${breakpoints.desktop.min}) {
    display: none;
  }
`

export const LeftSection = styled.div`
  display: flex;
  flex-grow: 2;
  gap: 16px;

  @media screen and (${breakpoints.desktop.min}) {
    gap: 8px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    gap: 16px;
  }
`
export const ProductImage = styled(Image)`
  width: 80px;
  height: 80px;

  @media screen and (${breakpoints.desktop.min}) {
    width: 93px;
    height: 93px;
  }
`

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 219px;

  @media screen and (${breakpoints.desktop.min}) {
    width: 237px;
  }
`

export const ContentTop = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
`

export const Title = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.platinum};
  height: 40px;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: -0.06px;
  margin-block-end: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: 2.4em;

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`

export const DiscountReturnabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (${breakpoints.desktop.min}) {
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`

export const DiscountContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding-block: 2px;
  padding-inline: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.green};
  width: fit-content;

  .discount-badge {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.055px;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const ReturnabilityWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`

export const ReturnabilityContainer = styled.div<ReturnabilityContainerProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  padding-block: 2px;
  padding-inline: 8px;
  border-radius: 4px;
  width: fit-content;
  ${({ returnabilityType }) => {
    if (
      returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE ||
      returnabilityType === ReturnabilityType.RETURNABLE_PLUS_BOTTLE_PLUS_BOX
    ) {
      return css`
        background: ${({ theme }) => theme.colors.grey60};
      `
    }
    return css`
      background: #12c2691a;
    `
  }}

  .returnability-icon {
    color: ${({ theme }) => theme.colors.green};
  }
`

export const ReturnabilityText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.055px;
  color: ${({ theme }) => theme.colors.green};
`

export const BottomSection = styled.button`
  display: none;

  .remove {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 4px;
    background: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.red};
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`

export const RightSection = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.colors.platinum80};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    gap: 8px;
    align-items: baseline;
  }
`

export const PricesWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  text-align: center;
  margin-block-end: -4px;
  max-width: 120px;
  min-width: 120px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    flex-direction: column-reverse;
  }

  @media screen and (${breakpoints.desktop.min}) {
    flex-direction: row;
    display: none;
    max-width: 145px;
    min-width: 145px;
  }

  @media screen and (${breakpoints.desktop.medium}) {
    display: inline-flex;
  }
`

export const DiscountPrice = styled.span`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  text-decoration-line: line-through;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.platinum80};
`

type ProductPriceProps = TotalPriceProps

export const ProductPrice = styled.span<ProductPriceProps>`
  ${({ hasDiscount }) => {
    if (hasDiscount)
      return css`
        color: ${({ theme }) => theme.colors.green};
      `
  }}
`

export const QuantityWrapper = styled.div<{ isGift: boolean }>`
  ${({ isGift }) => {
    if (isGift)
      return css`
        opacity: 0.5;
      `
  }}
  width: 96px;
  height: 26px;
  min-width: 96px;
  max-width: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-block-end: -4px;

  .decrease-quantity,
  .add-quantity {
    min-width: 26px;
    height: 26px;
  }

  .quantity {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
  }
`

interface TotalPriceProps {
  hasDiscount: boolean
}

export const TotalPrice = styled.div<TotalPriceProps>`
  min-width: 96px;
  max-width: 96px;
  width: 100%;
  display: grid;
  place-content: center;
  margin-block-end: -4px;
  ${({ hasDiscount }) => {
    if (hasDiscount)
      return css`
        color: ${({ theme }) => theme.colors.green};
      `
  }}
`

export const ReturnabilityTag = styled.div<{ returnability?: string }>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8px;
  padding-block: 4px;
  margin-bottom: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, returnability }) =>
    returnability === ReturnabilityType.RETURNABLE ? theme.colors.returnability : theme.colors.skyBlue};

  @media screen and (${breakpoints.tabletPortrait.min}) {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.06px;
  }
  a {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 325;
    line-height: 16px;
  }
`

export const RetornabilityContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`
