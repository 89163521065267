import styled from 'styled-components'
import { Icon } from '../../../components/Icon/Icon'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 0.111429px;
  margin: 0;
  color: ${({ theme }) => theme.colors.black80};
  -webkit-line-clamp: 2;
`

export const ProductsQuantity = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: ${({ theme }) => theme.colors.platinum80};
`

export const WrapperRemoveButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  gap: 4px;
  padding-block: 6px;
  padding-inline: 8px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export const TitleRemove = styled.span`
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.06px;
  color: ${({ theme }) => theme.colors.secondary};
`

export const IconRemove = styled(Icon)`
  color: ${({ theme }) => theme.colors.secondary};
`
