import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Button, getAuth, getCustomerEmail, useCart } from '@ecommerce/shared'
import { useUI } from '@ecommerce/shared/src/context/UI/ui.context'
import { useSuggestedProducts } from '@ecommerce/shared/src/context/Cart/suggested.context'
import { useCartStatus } from '@ecommerce/shared/src/context/ShoppingCart/cartStatusContext'
import { SuggestedMobileWrapper, SuggestedTitle } from './styled'
import SuggestedSlider from './SuggestedSlider'

const SuggestedActionButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .button {
    width: 100%;
  }
`

const SuggestedMobile = () => {
  const divRef = useRef<HTMLDivElement>(null)

  const { displaySuggestedProducts, closeSuggestedProducts } = useUI()
  const { isAddedSuggestedProduct } = useSuggestedProducts()
  const { setIsLoadingSuggestedSkus } = useCartStatus()
  const { submitOrder } = useCart()

  const isAuth = getAuth()
  const email = getCustomerEmail()

  return (
    <SuggestedMobileWrapper
      ref={divRef}
      isVisible={displaySuggestedProducts}
      className={displaySuggestedProducts ? 'visible' : 'hidden'}
    >
      <SuggestedTitle>Te podría interesar...</SuggestedTitle>
      <SuggestedSlider />
      <SuggestedActionButtons>
        <Button
          onClick={() => {
            submitOrder(undefined, email ?? undefined, isAuth)
            closeSuggestedProducts()
            setIsLoadingSuggestedSkus(false)
          }}
          className="button"
          type="button"
          btnType="primary"
        >
          {isAddedSuggestedProduct ? 'Ir a Pagar' : 'No, gracias'}
        </Button>
      </SuggestedActionButtons>
    </SuggestedMobileWrapper>
  )
}

export default SuggestedMobile
